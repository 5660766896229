import styled, { css, keyframes } from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`
const ani = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`

export const AnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: ${props => (props.hasWon ? 1 : 0)};
  background: linear-gradient(270deg, #2e6624, #de4949);
  background-size: 400%;
  animation: ${ani} 1s ease infinite;
`
export const MainContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: color 0.3s;
  color: ${props => (props.hasWon ? 'white' : 'black')};
`
export const PersonTitleContainer = styled.div`
  width: 200px;
  height: 36px;
  margin: 16px;
  overflow: hidden;
`
export const PersonContainer = styled.div`
  margin: 16px;
  word-wrap: break-word;
`
export const ImageContainer = styled.div`
  margin: 16px;
  width: 200px;
  height: 200px;
  background: linear-gradient(45deg, #fc466b 0%, #3f5efb 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
`
