import React, { useState } from 'react'
import { GlobalStyles } from 'common/styles'
import { useIntervalWhen } from 'rooks'
// not committing huge list of emails
//import persons from 'common/assets/persons.json'
const persons = null;

import {
  HeaderContainer,
  PersonContainer,
  ImageContainer,
  PersonTitleContainer,
  RowContainer,
  AnimatedBackground,
  MainContainer,
} from './styles'
import { mapNewArray, shuffleArray } from '../../utils/array'

const PersonImage = ({ person }) => {
  const imageUrl = `${person['E-Mail'].split('@')[0]}.jpg`
  return <ImageContainer>{imageUrl}</ImageContainer>
}
const RewardImage = ({ reward }) => {
  return <ImageContainer>{reward.image}</ImageContainer>
}

const REWARDS = [
  {
    name: 'Water',
    image: 'water.jpg',
  },
  {
    name: 'Pina Colada',
    image: 'pinacolada.jpg',
  },
  {
    name: 'Champagne',
    image: 'champagne.jpg',
  },
  {
    name: 'X-mas present',
    image: 'present.jpg',
  },
]

const App = () => {
  const [selectedPersonRewards, setSelectedPersonRewards] = useState([])
  const [wins, setWins] = useState(0)
  const [loses, setLoses] = useState(0)
  const [hasWon, setHasWon] = useState(false)

  useIntervalWhen(
    () => {
      const randomPersonRewards = []

      const total = wins + loses
      const ratio = wins / loses
      let needsWinReward = null
      if (total > 2) {
        if (ratio < 0.2) {
          needsWinReward = REWARDS[Math.floor(Math.random() * REWARDS.length)]
        }
      }

      const randomIndices = shuffleArray(mapNewArray(persons.length, i => i));
      for (let i = 0; i < 3; i++) {
        const rp = Math.floor(Math.random() * persons.length)
        const rr = Math.floor(Math.random() * REWARDS.length)
        randomPersonRewards.push({ person: persons[randomIndices[i]], reward: needsWinReward || REWARDS[rr] })
      }
      const firstRewardName = randomPersonRewards[0].reward.name
      const won = !randomPersonRewards.find(a => a.reward.name !== firstRewardName)
      if (won) {
        setWins(wins + 1)
      } else {
        setLoses(loses + 1)
      }
      setHasWon(won)
      setSelectedPersonRewards(randomPersonRewards)
    },
    1000,
    !!persons,
    true
  )
  return (
    <>
      <GlobalStyles />
      <AnimatedBackground hasWon={hasWon} />
      <MainContainer hasWon={hasWon}>
        <HeaderContainer>
          <h1>{hasWon ? `${selectedPersonRewards[0].reward.name} Time!!!` : 'XMAS LOTTERY'}</h1>
        </HeaderContainer>
        <RowContainer>
          {selectedPersonRewards.map((pr, i) => {
            const { person, reward } = pr
            return (
              <PersonContainer key={i}>
                <PersonTitleContainer>
                  {person.Vorname} {person.Nachname} {person.Position}
                </PersonTitleContainer>
                <PersonImage person={person} />
                <RewardImage reward={reward} />
              </PersonContainer>
            )
          })}
        </RowContainer>
      </MainContainer>
    </>
  )
}
export default App
